import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.8_react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.8_react@18.3.1/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.7_@playwright+test@1.47.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.7_@playwright+test@1.47.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.7_@playwright+test@1.47.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/contexts/shared/ui/layout/Layout.tsx\",\"import\":\"Archivo\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-archivo\"}],\"variableName\":\"archivo\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.7_@playwright+test@1.47.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/contexts/shared/ui/layout/Layout.tsx\",\"import\":\"Caudex\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-caudex\",\"weight\":\"400\"}],\"variableName\":\"caudex\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuItem"] */ "/vercel/path0/src/contexts/shared/ui/designSystem/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent"] */ "/vercel/path0/src/contexts/shared/ui/designSystem/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/contexts/shared/ui/designSystem/toast/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSelector"] */ "/vercel/path0/src/contexts/shared/ui/layout/ThemeSelector.tsx");
